<template>
    <div class="app-container">
        <template v-if="!addNewPlatform">
            <div class="el-col-md-24 p-10">
                <el-button type="danger" @click="dialogTutorialsVisible = !dialogTutorialsVisible"
                    >Cum integram un magazin?</el-button
                >
            </div>
            <el-dialog
                title="Tutorials"
                :visible.sync="dialogTutorialsVisible"
                width="80%"
                top="3vh"
                custom-class="custom_dialog"
            >
                <el-tabs tab-position="left" style="height: 800px">
                    <el-tab-pane :label="tutorial.platform" v-for="(tutorial, index) in tutorials" :key="'t' + index">
                        <el-tabs>
                            <el-tab-pane
                                :label="video.title"
                                v-for="(video, index) in tutorial.videos"
                                :key="'v' + index"
                            >
                                <p v-if="typeof video.info !== 'undefined'">{{ video.info }}</p>
                                <iframe width="80%" height="700px" :src="video.youtubeUrl"> </iframe>
                            </el-tab-pane>
                        </el-tabs>
                    </el-tab-pane>
                </el-tabs>
            </el-dialog>
            <div class="el-col-md-8 p-10">
                <el-card class="box-card">
                    <div slot="header" class="clearfix dashboard-card-title">
                        <svg-icon icon-class="cart" />
                        &nbsp;
                        <span class="head-title">{{ $t('shop.add_new') }}</span>
                    </div>
                    <div class="card-body">
                        <el-select v-model="newPlatform" placeholder="Select" class="center w-50">
                            <template v-for="item in platforms">
                                <template v-if="item.experimental">
                                    <el-option
                                        v-if="experimentalInterface"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </template>
                                <template v-else>
                                    <el-option :key="item.value" :label="item.label" :value="item.value"> </el-option>
                                </template>
                            </template>
                        </el-select>
                        <p>
                            <el-button class="center" type="success" @click="add" :disabled="newPlatform === null">{{
                                $t('general.add')
                            }}</el-button>
                        </p>
                    </div>
                </el-card>
            </div>
            <div class="el-col-md-8 p-10" v-for="shop in shops" :key="shop._id">
                <el-card class="box-card">
                    <div slot="header" class="clearfix dashboard-card-title">
                        <svg-icon icon-class="cart" />
                        &nbsp;
                        <span class="head-title">{{ shop.name }}</span>
                    </div>
                    <div class="card-body">
                        <p>{{ $t('shop.name') }}: {{ shop.name }}</p>
                        <p>{{ $t('shop.platform') }}: {{ shop.platform }}</p>
                        <p>Endpoint: {{ shop.shopUrl }}</p>
                        <p>Ultima actualizare stoc/pret: {{ shop.last_runupdate }}</p>
                        <p>Ultima actualizare produse: {{ shop.last_runinsert }}</p>
                        <p>
                            <router-link :to="{ name: 'shopView', params: { id: shop._id } }">
                                <el-button plain class="fullWidth" type="primary">{{
                                    $t('general.details')
                                }}</el-button>
                            </router-link>
                        </p>
                    </div>
                </el-card>
            </div>
        </template>
        <component
            v-else
            :is="component"
            :platform="newPlatform"
            @back="addNewPlatform = $event"
            :ready="true"
        ></component>
    </div>
</template>

<style scoped>
.p-10 {
    padding: 10px;
}
.center {
    display: block;
    margin: 0 auto;
}
.w-50 {
    width: 50%;
}
::v-deep .custom_dialog > .el-dialog__body {
    padding: 0;
}
::v-deep .custom_dialog > .el-dialog__header {
    display: none;
}
</style>

<script>
import { createIntegrationsStore } from '@/store/modules/integrations/index.js';

export default {
    data() {
        return {
            platforms: [
                {
                    value: 'Prestashop',
                    label: 'Prestashop',
                },
                {
                    value: 'Magento2',
                    label: 'Magento2',
                },
                {
                    value: 'WooCommerce',
                    label: 'WooCommerce  (WordPress)',
                },
                {
                    value: 'OpenCart',
                    label: 'OpenCart',
                    //experimental: true
                },
                {
                    value: 'Shopify',
                    label: 'Shopify',
                    experimental: true,
                },
            ],
            newPlatform: null,
            addNewPlatform: false,
            component: 'addShop',
            shops: [],
            experimentalInterface: null,
            dialogTutorialsVisible: false,
            tutorials: [
                {
                    platform: 'Prestashop',
                    videos: [
                        {
                            title: 'Setare conexiune',
                            youtubeUrl: 'https://www.youtube.com/embed/HFX42dr0Q6Q',
                        },
                        {
                            title: 'Mapare categorii',
                            youtubeUrl: 'https://www.youtube.com/embed/McMiS9VRpC4',
                        },
                    ],
                },
                {
                    platform: 'Magento 2',
                    videos: [
                        {
                            title: 'Setare conexiune',
                            youtubeUrl: 'https://www.youtube.com/embed/DOl7B-O8Dfo',
                        },
                        {
                            title: 'Mapare categorii',
                            youtubeUrl: 'https://www.youtube.com/embed/McMiS9VRpC4',
                            info: 'In acest exemplu a fost folosit Prestahop, dar setarile sunt asemanatoare cu Magento 2',
                        },
                    ],
                },
                {
                    platform: 'WooCommerce (WordPress)',
                    videos: [
                        {
                            title: 'Setare conexiune',
                            youtubeUrl: 'https://www.youtube.com/embed/CFkvfJV8Ps0',
                        },
                        {
                            title: 'Mapare categorii',
                            youtubeUrl: 'https://www.youtube.com/embed/McMiS9VRpC4',
                            info: 'In acest exemplu a fost folosit Prestahop, dar setarile sunt asemanatoare cu WordPress (WooCommerce)',
                        },
                    ],
                },
                {
                    platform: 'OpenCart',
                    videos: [
                        {
                            title: 'Setare conexiune OpenCart 3.x',
                            youtubeUrl: 'https://www.youtube.com/embed/Gv8TkTss0Kg',
                        },
                        {
                            title: 'Setare conexiune OpenCart 2.x',
                            youtubeUrl: 'https://www.youtube.com/embed/Y5-dkuRSh0w',
                        },
                        {
                            title: 'Mapare categorii',
                            youtubeUrl: 'https://www.youtube.com/embed/McMiS9VRpC4',
                            info: 'In acest exemplu a fost folosit Prestahop, dar setarile sunt asemanatoare cu OpenCart',
                        },
                        {
                            title: 'Actualizare Modul OpenCart 3.x',
                            youtubeUrl: 'https://www.youtube.com/embed/Cv0I2jMPv6Y',
                        },
                        {
                            title: 'Actualizare Modul OpenCart 2.x',
                            youtubeUrl: 'https://www.youtube.com/embed/C-jthS_lhoc',
                        },
                    ],
                },
            ],
        };
    },
    components: {
        addShop: () => import('./shop'),
    },
    beforeCreate() {
        this.$store.registerModule('integrations', createIntegrationsStore());
    },
    beforeDestroy() {
        this.$store.unregisterModule('integrations');
    },
    created() {
        this.$store
            .dispatch('integrations/getUserShops')
            .then((res) => {
                if (res.error === 0 && res.message.success) {
                    this.shops = res.message.data;
                }
            })
            .catch((err) => {
                this.$reportToSentry(err, {
                    extra: {
                        fn: 'created',
                        dispatch: 'getUserShops',
                    },
                });
            });

        this.$store
            .dispatch('integrations/showExperimentalInferface')
            .then((res) => {
                if (res.error === 0) {
                    this.experimentalInterface = res.message.data;
                }
            })
            .catch((err) => {
                this.$reportToSentry(err, {
                    extra: {
                        fn: 'created',
                        dispatch: 'showExperimentalInferface',
                    },
                });
            });
    },
    methods: {
        add() {
            this.addNewPlatform = true;
        },
    },
};
</script>
